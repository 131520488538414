/*
  Founders Grotesk: Bold
*/
@font-face {
  font-family: "Founders Grotesk";
  src: url("./fonts/FoundersGroteskWeb-Bold.eot");
  src: url("./fonts/FoundersGroteskWeb-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/FoundersGroteskWeb-Bold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

/*
  Founders Grotesk: Regular
*/
@font-face {
  font-family: "Founders Grotesk";
  src: url("./fonts/FoundersGroteskWeb-Regular.eot");
  src: url("./fonts/FoundersGroteskWeb-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/FoundersGroteskWeb-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

/*
  Founders Grotesk: Light
*/
@font-face {
  font-family: "Founders Grotesk";
  src: url("./fonts/FoundersGroteskWeb-Light.eot");
  src: url("./fonts/FoundersGroteskWeb-Light.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/FoundersGroteskWeb-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

/*
  Inter
*/
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap");

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  font-family: "Inter", sans-serif;
  font-size: 17px;
  line-height: 24px;
  font-variant-ligatures: common-ligatures;
  text-rendering: optimizeLegibility;
  color: var(--font-color);
  background-color: var(--bg-color);
}

* {
  box-sizing: border-box;
}

:root {
  --spacing-xs: 5px;
  --spacing-s: 10px;
  --spacing-md: 20px;
  --spacing-l: 60px;
  --spacing-xl: 120px;
  --bg-color: #ffffff;
  --img-color: #f1f2f6;
  --rule-color: #e6e8ef;
  --link-color: #767676;
  --font-color: #282828;
  --icon-stroke: #282828;
  --icon-stroke-active: #fafafa;
  --icon-fill: #282828;
  --font-heading-color: #767676;

  @media (prefers-color-scheme: dark) {
    --bg-color: #232325;
    --font-color: #fafafa;
    --font-heading-color: #999;
    --link-color: #999;
    --icon-stroke: #fafafa;
    --icon-stroke-active: #282828;
    --icon-fill: #fafafa;
    --img-color: #1e1e1f;
    --rule-color: #141415;
  }
}
