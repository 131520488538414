@keyframes fadeInImg {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.collection {
  padding-top: 130px;

  &__period {
    border-top: 1px solid var(--rule-color);
    margin-bottom: var(--spacing-l);
    padding: var(--spacing-md);
    font-size: 17px;
    line-height: 24px;
    display: flex;
    flex-direction: column;

    .period {
      color: var(--font-heading-color);
    }
  }

  &__tooltip {
    position: relative;
    z-index: 99;
    span {
      padding: 4px 8px;
      font-size: 14px;
      line-height: 20px;
      display: block;
      color: var(--bg-color);
      background: var(--font-color);
    }
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding: 10px 20px 18px;
  }

  &__item {
    padding: 0 1px 2px 1px;
    width: calc((6 / 12) * 100%);
    //
    @media (min-width: 640px) {
      width: calc((3 / 12) * 100%);
    }
    //
    @media (min-width: 900px) {
      width: calc((3 / 12) * 100%);
    }

    //
    @media (min-width: 1200px) {
      width: calc((2 / 12) * 100%);
    }

    figure {
      background-color: var(--img-color);

      img {
        // display: none;

        &.img-loading {
          opacity: 0;
        }

        &.img-loaded {
          animation: fadeInImg cubic-bezier(0.23, 1, 0.32, 1) 1;
          position: relative;
          opacity: 0;
          animation-fill-mode: forwards;
          animation-duration: 0.7s;
          animation-delay: 0.1s;
          z-index: 0;
        }
      }

      .a11y {
        display: none;
      }
    }
  }
}

.aspect-ratio {
  height: 0;
  position: relative;
}
.aspect-ratio--8x5 {
  padding-bottom: 62.5%;
}
.aspect-ratio--object {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}
