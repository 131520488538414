.toggle {
  a {
    color: var(--font-color);
    display: flex;
  }
  .label {
  }

  // --icon-stroke: #fafafa;
  // --icon-stroke-active: #282828;

  .icon {
    width: 20px;
    height: 20px;
    // border: 2px solid var(--icon-stroke);
    border-radius: 50%;
    margin-right: var(--spacing-s);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 250ms ease;

    svg {
      transform: rotate(-45deg);
      transition: transform 250ms ease;
      display: block;
      // flex-grow: 0;
      // flex-shrink: 0;

      path {
        transition: stroke 250ms ease;
        stroke: var(--icon-stroke);
      }
    }

    &.is-active {
      // background-color: var(--icon-stroke);
      svg {
        transform: rotate(0deg);

        path {
          // stroke: var(--icon-stroke-active);
        }
      }
    }
  }
}
