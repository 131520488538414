.mn {
  margin-bottom: 120px;
  font-size: 17px;
  line-height: 24px;
}

header {
  display: flex;
  justify-content: space-between;
  padding: var(--spacing-md) var(--spacing-s) 0;

  a {
    display: inline-block;
    color: inherit;
    text-decoration: none;
    outline: none;
    color: var(--font-heading-color);
    display: flex;
    align-items: center;
  }

  .mn__name {
    font-weight: 500;
  }

  .mn__duration {
    color: var(--font-heading-color);
  }

  .mn__bio {
    display: flex;
    margin-top: var(--spacing-md);
    @media (min-width: 640px) {
      justify-content: flex-start;
    }
  }

  .mn__options {
    position: relative;
    display: flex;
    justify-content: flex-end;

    &__email {
      position: relative;
      height: 24px;
    }
  }
}
