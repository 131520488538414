// Breakpoints
// ----------------------------
$breakpoints: (
  xs: 360px,
  s: 640px,
  m: 960px,
  l: 1200px,
  xl: 1480px,
);

@mixin breakpoint($name) {
  @if not map-has-key($breakpoints, $name) {
    @warn "Warning: `#{$name}` is not a valid breakpoint name.";
  } @else {
    @media (min-width: map-get($breakpoints, $name)) {
      @content;
    }
  }
}
