@use "sass:math";
@import "../../lib/Breakpoints.scss";

@mixin flex-basis($value: auto) {
  flex-basis: $value;
  max-width: $value;
  width: $value;
}

@mixin grid-generate-columns($grid-col-count, $postfix) {
  @for $i from 0 through $grid-col-count {
    .col--#{$postfix}-#{$i} {
      @include flex-basis($i * math.div(100%, $grid-col-count));
    }

    .offset--#{$postfix}-#{$i} {
      margin-left: $i * math.div(100%, $grid-col-count);
    }
  }
}

$col-gutter: 20px;

.container {
  margin-right: auto;
  margin-left: auto;
  width: calc(100vw - 40px);
  box-sizing: border-box;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 calc((40px / 2) * -1);
}

.col,
[class*="col--"] {
  @include flex-basis(100%);
  padding: 0 calc(40px / 2);
  margin: 0;
  box-sizing: border-box;
}

@include grid-generate-columns(12, "xs");

@include breakpoint(s) {
  @include grid-generate-columns(12, "sm");
}
@include breakpoint(m) {
  @include grid-generate-columns(12, "md");
}

@include breakpoint(l) {
  @include grid-generate-columns(12, "lg");
}

@include breakpoint(xl) {
  @include grid-generate-columns(12, "xl");
}
