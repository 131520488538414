.bio {
  overflow: hidden;
  padding: 0 var(--spacing-s);
  font-size: 17px;
  line-height: 24px;

  a {
    text-decoration: none;
    outline: none;
    color: var(--link-color);
    position: relative;
    display: inline-block;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: var(--link-color);
      opacity: 1;
      transition: opacity 250ms ease;
    }

    &:hover {
      &:after {
        opacity: 0;
      }
    }
  }

  p {
    margin-bottom: var(--spacing-s);
  }

  .bio-heading {
    margin-bottom: var(--spacing-s);
    color: var(--font-color);
  }

  .bio-overview {
    margin-bottom: var(--spacing-l);
  }
  .bio-elsewhere {
    // margin-bottom: var(--spacing-xl);

    color: var(--font-heading-color);
  }

  .bio-content {
    color: var(--font-heading-color);
  }

  .bio-local {
    margin-top: var(--spacing-md);
  }

  .elsewhere-item {
    display: inline-block;
  }
}
